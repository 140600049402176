(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fiverr-private/theme"), require("@fiverr/i18n/singleton"), require("axios"), require("classnames"), require("lodash"), require("lodash/isEqual"), require("lodash/noop"), require("lodash/omit"), require("prop-types"), require("react"), require("react-dom"), require("ua-parser-js"));
	else if(typeof define === 'function' && define.amd)
		define(["@fiverr-private/theme", "@fiverr/i18n/singleton", "axios", "classnames", "lodash", "lodash/isEqual", "lodash/noop", "lodash/omit", "prop-types", "react", "react-dom", "ua-parser-js"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@fiverr-private/theme"), require("@fiverr/i18n/singleton"), require("axios"), require("classnames"), require("lodash"), require("lodash/isEqual"), require("lodash/noop"), require("lodash/omit"), require("prop-types"), require("react"), require("react-dom"), require("ua-parser-js")) : factory(root["theme"], root["i18n"], root["axios"], root["classNames"], root["_"], root["_"]["isEqual"], root["_"]["noop"], root["_"]["omit"], root["PropTypes"], root["React17"], root["ReactDOM17"], root["uaParser"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE_Jt0T__, __WEBPACK_EXTERNAL_MODULE_jgnj__, __WEBPACK_EXTERNAL_MODULE__jHP__, __WEBPACK_EXTERNAL_MODULE_IX_1__, __WEBPACK_EXTERNAL_MODULE_luq0__, __WEBPACK_EXTERNAL_MODULE_ydk___, __WEBPACK_EXTERNAL_MODULE_poII__, __WEBPACK_EXTERNAL_MODULE_WYv1__, __WEBPACK_EXTERNAL_MODULE_JjgO__, __WEBPACK_EXTERNAL_MODULE_yHGu__, __WEBPACK_EXTERNAL_MODULE__4LQx__, __WEBPACK_EXTERNAL_MODULE_glLg__) => {
return 