import { bigQuery, mixpanel } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { init, triggerSignUp, triggerSignIn as triggerSignInModal } from '@fiverr-private/user_session';
import { sessionStorage } from '@fiverr-private/futile';
import { HANDLER_TYPE, TRIGGER_MODAL } from '../../types';
import {
    AUTO_SHOW_TRIGGER,
    HANDLER_TYPES,
    QUERY_PARAMS,
    SIGN_UP_AUTO_SHOWN_KEY,
    TRIGGER_SELECTORS,
    EVENTS,
} from '../../constants';

// **THIS MODULE IS TEMPORARY AND SHOULD BE REMOVED ONCE HEADER USES THE SESSION PACKAGE DIRECTLY**

/**
 * Creates sign up modal options.
 */
const getSignUpOptions = (optionsOverrides = {}): TRIGGER_MODAL['options'] => {
    const { queryParameters } = getContext();
    const referralEmail = queryParameters?.[QUERY_PARAMS.REFERRAL_EMAIL];

    const referralOptions = referralEmail
        ? {
              isSticky: true,
              initialValues: {
                  email: typeof referralEmail === 'string' ? decodeURIComponent(referralEmail) : '',
              },
          }
        : {};

    return {
        ...referralOptions,
        ...optionsOverrides,
    };
};

/**
 * Tracks a user session event type.
 * @param eventType The internal event type (One of EVENT_TYPES)
 * @param customBigQueryEvent The custom event to sent to big query additional to the default one.
 * @param sourceComponent The source component which triggered the event.
 */
const trackEvent = ({
    handlerType,
    customBigQueryEvent,
    sourceComponent,
}: {
    handlerType: HANDLER_TYPE;
    customBigQueryEvent: string;
    sourceComponent: string;
}) => {
    const platforms = EVENTS[handlerType];

    mixpanel.track(platforms.mixpanel, {
        'Source Component': sourceComponent,
        'View Type': 'Pop-up',
    });

    bigQuery.send({
        type: platforms.bigQuery,
        group: 'signin flow',
    });

    if (customBigQueryEvent) {
        bigQuery.send({
            type: customBigQueryEvent,
            group: 'signin flow',
        });
    }
};

/**
 * Open Sign Up modal.
 */
const triggerSignUpModal = (optionsOverrides = {}) => triggerSignUp(getSignUpOptions(optionsOverrides));

/**
 * A map of element selectors, and their respective handler exported from `user_session` package.
 * @see https://github.com/fiverr/user_session_package#external-modal-triggers
 */
const LISTENER_MAP = {
    [HANDLER_TYPES.SIGN_IN]: triggerSignInModal,
    [HANDLER_TYPES.SIGN_UP]: triggerSignUpModal,
} as const;

/**
 * Triggers user session click handler.
 */
const userSessionClickHandler = (event: Event, handlerType: HANDLER_TYPE) => {
    const { target } = event;

    if (!(target instanceof HTMLElement)) {
        return;
    }

    const { sourceComponent, eventType: customBigQueryEvent } = target.dataset as {
        sourceComponent: string;
        eventType: string;
    };

    event.preventDefault();

    trackEvent({
        handlerType,
        customBigQueryEvent,
        sourceComponent,
    });

    LISTENER_MAP[handlerType]();
};

/**
 * Binds click handlers for all trigger elements selected by handler type.
 * @param handlerType - The signing flow handler type.
 */
const bindListener = (handlerType: HANDLER_TYPE) => {
    const triggerSelector = TRIGGER_SELECTORS[handlerType];
    const triggerElements = document.querySelectorAll(triggerSelector);

    triggerElements.forEach((triggerElement) =>
        triggerElement.addEventListener('click', (event) => {
            userSessionClickHandler(event, handlerType);
        })
    );
};

const triggerSignUpAutoShow = () => {
    const { userId, queryParameters } = getContext();
    if (queryParameters?.[QUERY_PARAMS.SHOW_JOIN] && !userId) {
        sessionStorage.set(SIGN_UP_AUTO_SHOWN_KEY, true);

        triggerSignUpModal({
            modalOptions: {
                triggerType: AUTO_SHOW_TRIGGER,
            },
            forceModal: true,
        });
    }
};

export const onLoad = () => {
    bindListener(HANDLER_TYPES.SIGN_IN);
    bindListener(HANDLER_TYPES.SIGN_UP);
};

/**
 * Handles client side initialization
 */
const main = () => {
    init();
    triggerSignUpAutoShow();

    window.addEventListener('load', onLoad);
};

export default main;
